import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import band1 from "../../../assets/Corona/band_sant.png";
import band2 from "../../../assets/Corona/band_arg.png";
import x10 from "../../../assets/Dolchester-x10.png";
import borde from "../../../assets/recuadro-dorado.svg";
import dolchesterDorado from "../../../assets/DolchesterGolden2.png";
import dolchesterSilver from "../../../assets/DolchesterSilver2.png";
import Footer from "../../../components/footer/Footer.jsx";
import dolchesterVertical from "../../../assets/dolchester-vertical.svg"
import boxesDelchester from "../../../assets/DOLCHESTER_Golden_x10_04.png"

export default function Dolchester() {
  const navigate = useNavigate();
  const [video, setVideo] = useState("");

  const getData = async () => {
    try {
      const response = await fetch(
        "https://api-tabaquera-psi.vercel.app/api/videos"
      );
      const json = await response.json();
      setVideo(json[3].link);
    } catch (error) { }
  };

  return (
    <>
      {video !== "sin video" && video ? (
        <iframe
          className={styles.video}
          src={video}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
        </iframe>
      ) : (
        <div className={styles.backgroundContainer}>
          <div className={styles.fourSection}>
            <div className={styles.fondoContainer}>
            </div>
            <div className={styles.boxContainer}>
            </div>
          </div>
        </div>
      )}
      <div className={styles.goldenSeparator} />
      <div className={styles.fifthSection}>
        <div className={styles.conteinerProductFifthSection}>
          <div className={styles.contenedorBorde}>
            <img
              src={borde}
              loading="lazy"
              alt="borde"
              className={styles.bordeDolche}
            />
          </div>
          <div className={styles.textContainerLeft}>
            <h2 className={styles.titlefifthSection}>
              DOLCHESTER GOLDEN EDITION
            </h2>
            <p className={styles.boxesP}>
              Un sabor diferente y con gran personalidad para clásicos destacados. Una declaración de actitud. Un diseño evolucionado potenciando al mismo blend de calidad de tabaco Virginia. El mismo sabor de siempre suma una nueva presentación mucho más práctica por 10 cigarrillos.
            </p>
            <p className={styles.details}>BOX x10 | BOX x20</p>
          </div>
          <img
            src={dolchesterDorado}
            alt="DOLCHESTER ORIGINAL BLEND"
            className={styles.pierBoxesRed}
          />
        </div>
        <div
          className={`${styles.conteinerProductFifthSection} ${styles.invertContainer}`}
        >
          <div className={styles.contenedorBorde}>
            <img
              src={borde}
              loading="lazy"
              alt="borde"
              className={styles.bordeDolche}
            />
          </div>
          <div className={styles.textContainerRight}>
            <h2 className={styles.titlefifthSection}>
              DOLCHESTER SILVER EDITION
            </h2>
            <p className={styles.boxesP}>
              Una declaración de actitud. Un tope de gama, con sabor diferente y con gran personalidad. Un diseño evolucionado potenciando al mismo blend de calidad de tabaco Virginia. El mismo sabor de siempre suma una nueva presentación mucho más práctica por 10 cigarrillos.
            </p>
            <p className={styles.details}>BOX x10 | BOX x20</p>
          </div>
          <img
            src={dolchesterSilver}
            alt="DOLCHESTER DELUXE BLEND"
            className={styles.pierBoxes}
          />
        </div>
      </div>

      <div className={styles.dolchesterSection}>
        <div className={styles.text}>
          <h2 className={styles.title}>DOLCHESTER UNA MARCA 100% ARGENTINA</h2>
          <div>
            <p className={styles.subtitle}>
              Desde Santiago del Estero creamos productos Argentinos, que compiten con
              las grandes marcas internacionales. Estamos enfocados en satisfacer las exigencias
              de un mercado cada vez más competitivo imponiendo siempre productos
              de Industria Nacional.
            </p>
          </div>
          <div className={styles.band}>
            <img src={band1} alt="Bandera" />
            <img src={band2} alt="Bardera" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
