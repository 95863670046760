import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import band1 from "../../../assets/Corona/band_sant.png";
import band2 from "../../../assets/Corona/band_arg.png";
import pierRojos from "../../../assets/pier-rojo.svg";
import pierVerdes from "../../../assets/pier-verde.svg";
import styles from "./styles.module.css";
import Footer from "../../../components/footer/Footer.jsx";
import pierLogo from "../../../assets/pier-logo.svg";
import pierLetras from "../../../assets/pier-letras.svg";
import pierBox from "../../../assets/pier-productos.png";

export default function Pier() {
  const navigate = useNavigate();
  const [video, setVideo] = useState("");

  const getData = async () => {
    try {
      const response = await fetch(
        "https://api-tabaquera-psi.vercel.app/api/videos"
      );
      const json = await response.json();
      setVideo(json[1].link);
    } catch (error) { }
  };

  return (
    <>
      {video !== "sin video" && video ? (
        <iframe
          className={styles.video}
          src={video}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
        </iframe>
      ) : (
        <div className={styles.firstSection}>
          <div className={styles.flexColum}>
            <img
              src={pierLogo}
              alt="LOGOTIPO PIER"
              className={styles.marca1}
            />
            <img
              src={pierLetras}
              alt="NOMBRE MARCA PIER"
              className={styles.marca2}
            />
          </div>

          <div className={styles.contenedor}>
            <div className={styles.containerFaltante}>
              <img
                src={pierBox}
                alt="NOMBRE MARCA PIER"
                className={styles.pierBox}
              />
            </div>
          </div>

        </div>
      )}

      <div className={styles.contenedorSecond}>
        <div className={styles.secondSection}>
          <div className={styles.conteinerProductLeft}>
            <div className={styles.textProductBox}>
              <h2 className={styles.title}>PIER ORIGINAL RED BLEND</h2>
              <p className={styles.subtitle}>
                El clásico cigarrillo PIER descubriendo un nuevo horizonte. Un
                diseño evolucionado potenciando al mismo blend de calidad de
                tabaco Virginia. El mismosabor de siempre suma una nueva
                presentación mucho más práctica por 10 cigarrillos.
              </p>
              <p className={styles.details}>BOX x10 | BOX x20</p>
            </div>
            <img
              src={pierRojos}
              alt="PIER ORIGINAL RED BLEND"
              className={styles.pierBoxes}
            />
          </div>
          <div
            className={`${styles.conteinerProductRight} ${styles.invertContainer}`}
          >
            <div className={styles.textProductBox}>
              <h2 className={styles.title}>PIER GREEN BLEND</h2>
              <p className={styles.subtitle}>
                El mismo cigarrillo de siempre en su variante Menthol. Un aire
                de frescura para el mismo blend innovador. La evolución de una
                imagen que abraza al mismo sabor. Ahora también en una nueva e
                innovadora presentación por 10 cigarrillos, mucho más cerca
                tuyo.
              </p>
              <p className={styles.details}>BOX x10 | BOX x20</p>
            </div>
            <img
              src={pierVerdes}
              alt="PIER GREEN BLEND"
              className={styles.pierBoxes}
            />
          </div>
        </div>
      </div>

      <div className={styles.thirdSection}>
        <div className={styles.text}>
          <h2 className={styles.title}>PIER UNA MARCA 100% ARGENTINA</h2>
          <div>
            <p className={styles.subtitle}>
              Desde Santiago del Estero creamos productos Argentinos, que compiten con
              las grandes marcas internacionales. Estamos enfocados en satisfacer las exigencias
              de un mercado cada vez más competitivo imponiendo siempre productos
              de Industria Nacional.
            </p>
          </div>
          <div className={styles.band}>
            <img src={band1} alt="Bandera" />
            <img src={band2} alt="Bardera" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
