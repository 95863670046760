import React, { useEffect, useState } from 'react'
import styles from './cookies.module.css'
import { Link } from 'react-router-dom';

export default function CookiesModal() {
  const [showModal, setShowModal] = useState(false);
  const [showModalConifg, setShowModalConig] = useState(false);
  const [cookieSettings, setCookieSettings] = useState({
    necessary: false,
    performance: false,
    targeting: false
  });

  useEffect(() => {
    if (localStorage.getItem('cookie')) {
      setShowModal(false);
    } else {
      setShowModal(true); // Mostrar el modal al cargar la página
    }
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
    localStorage.setItem('cookie', true);
  };

  const toggleModalConfig = () => {
    setShowModalConig(!showModalConifg);
  };

  const handleCookieSettingsChange = (event) => {
    const { name, checked } = event.target;
    setCookieSettings((prevState) => ({
      ...prevState,
      [name]: checked
    }));
  };

  return (
    <div>
      {showModal && (
        <div className={styles.modal}>
          <p>
            Al hacer clic en “Aceptar todas las cookies”, usted acepta que las cookies se guarden en su dispositivo para mejorar la navegación del sitio, analizar el
            uso del mismo, y colaborar con nuestros estudios para marketing de acuerdo a lo permitido por la ley local. Los datos personales que recopilamos sobre
            usted se utilizarán tal y como se describe en nuestra. <Link to="/politicas-privacidad" className={styles.politicas}>Política de privacidad</Link>
          </p>
          <h4 className={styles.configCookies} onClick={toggleModalConfig}>Configuración de cookies</h4>

          <button onClick={toggleModal} className={styles.footerButton}>
            Aceptar todas las cookies
          </button>
        </div>
      )}

      {showModalConifg && (
        <div className={styles.modal}>
          <h4 className={styles.configCookies}>Configuración de cookies</h4>
          <div>
            <label>
              <input
                type="checkbox"
                name="necessary"
                checked={cookieSettings.necessary}
                onChange={handleCookieSettingsChange}
              />
              Cookies estrictamente necesarias
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="performance"
                checked={cookieSettings.performance}
                onChange={handleCookieSettingsChange}
              />
              Cookies de rendimiento
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                name="targeting"
                checked={cookieSettings.targeting}
                onChange={handleCookieSettingsChange}
              />
              Cookies de targeting y publicidad
            </label>
          </div>
          <button  onClick={toggleModalConfig} className={styles.footerButton}>
            cerrar
          </button>
        </div>
      )}


    </div>
  );
}