import React from "react";
import band1 from "../../../assets/Corona/band_sant.png";
import band2 from "../../../assets/Corona/band_arg.png";
import liverRed from "../../../assets/Liverpool/LiverpoolRed.png";
import liverBlue from "../../../assets/Liverpool/LiverpoolBlue.png";
import liverBlueBox from "../../../assets/Liverpool/LiverpoolBox.png";
import liverBlueBox2 from "../../../assets/Liverpool/LiverBlueDestacado.png";
import liverGreen from "../../../assets/Liverpool/LiverpoolGreen.png";
import bluePopLogo from "../../../assets/Liverpool/BluePopLogo.png";
import bluePop from "../../../assets/Liverpool/BluePop.png";
import styles from "./styles.module.css";
import Footer from "../../../components/footer/Footer.jsx";
import liverLogo from "../../../assets/Liverpool/LiverpoolGrisVertical.png";
import grayDivider from "../../../assets/Liverpool/BordeGris.png";

export default function Liverpool() {

  return (
    <>
      <div className={styles.firstSection}>
        <div className={styles.flexColum}>
          <img
            src={liverLogo}
            alt="NOMBRE MARCA LIVERPOOL"
            className={styles.marca2}
          />
        </div>

        <div className={styles.contenedor}>
          <div className={styles.containerFaltante}>
            <img
              src={liverBlueBox}
              alt="NOMBRE MARCA LIVERPOOL"
              className={styles.pierBox}
            />
          </div>
        </div>

        <div className={styles.slogan}>
          <div className={styles.sloganContainer}>
            <h2 className={styles.sloganText}>NUEVO LIVERPOOL CONVERTIBLE<br />CON CÁPSULA DE SABOR</h2>
            <img
              src={bluePopLogo}
              alt="NOMBRE MARCA LIVERPOOL"
              className={styles.bluePopLogo}
            />
          </div>
        </div>

      </div>
      <div className={styles.grayDivider}>
        <img src={grayDivider} alt="divisor" />
      </div>
      <div className={styles.liverpoolBlue}>
        <div className={styles.liverBoxContainer}>
          <div className={styles.liverBlueText}>
            <div>
              <h2 className={styles.title}>LIVERPOOL BLUE POP</h2>
              <div>
                <p className={styles.subtitlePop}>
                  Nuevo Liverpool convertible con cápsula de sabor. Una explosión de frescura opcional que potencia el clásico sabor Liverpool. Producido con altos estándares internacionales que aseguran su calidad.
                </p>
                <p className={styles.details}>BOX x20</p>
              </div>
            </div>
            <img
              className={styles.liverBox}
              src={liverBlueBox2}
              alt="Liverpool Blue Pop"
            />
            <img
              className={styles.bluePop}
              src={bluePop}
              alt="Blue Pop"
            />
          </div>
        </div>
      </div>
      <div className={styles.contenedorSecond}>
        <div className={styles.secondSection}>
          <div
            className={`${styles.conteinerProductRight} ${styles.invertContainer}`}
          >
            <div className={styles.textProductBox}>
              <h2 className={styles.title}>LIVERPOOL RED</h2>
              <p className={styles.subtitle}>
                Liverpool es la interpretación de una ciudad, que sale al puerto y se encuentra con el mar y toda su inmensidad. En su variable RED presenta un blend único, con personalidad y carácter.
              </p>
              <p className={styles.details}>BOX x20</p>
            </div>
            <img
              src={liverRed}
              alt="LIVERPOOL"
              className={styles.pierBoxes}
            />
          </div>
          <div className={styles.conteinerProductLeft}>
            <div className={styles.textProductBox}>
              <h2 className={styles.title} style={{ color: '#326FAD' }}>LIVERPOOL BLUE</h2>
              <p className={styles.subtitle}>
                Liverpool es la interpretación de una ciudad, que sale al puerto y se encuentra con el mar y toda su inmensidad. En su variable BLUE presenta un blend suave sin perder personalidad ni carácter.
              </p>
              <p className={styles.details}>BOX x20</p>
            </div>
            <img
              src={liverBlue}
              alt="LIVERPOOL"
              className={styles.pierBoxes}
            />
          </div>
          <div
            className={`${styles.conteinerProductRight} ${styles.invertContainer}`}
          >
            <div className={styles.textProductBox}>
              <h2 className={styles.title} style={{ color: '#119479' }}>LIVERPOOL GREEN</h2>
              <p className={styles.subtitle}>
                Liverpool es la interpretación de una ciudad, que sale al puerto y se encuentra con el mar y toda su inmensidad. En su variable GREEN presenta un blend único con notas de menthol que refrescan la experiencia.
              </p>
              <p className={styles.details}>BOX x20</p>
            </div>
            <img
              src={liverGreen}
              alt="LIVERPOOL"
              className={styles.pierBoxes}
            />
          </div>
        </div>
      </div>

      <div className={styles.thirdSection}>
        <div className={styles.text}>
          <h2 className={styles.title}>LIVERPOOL UNA MARCA 100% ARGENTINA</h2>
          <div>
            <p className={styles.subtitle}>
              Desde Santiago del Estero creamos productos Argentinos, que compiten con
              las grandes marcas internacionales. Estamos enfocados en satisfacer las exigencias
              de un mercado cada vez más competitivo imponiendo siempre productos
              de Industria Nacional.
            </p>
          </div>
          <div className={styles.band}>
            <img src={band1} alt="Bandera" />
            <img src={band2} alt="Bardera" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
