import React from "react";
import styles from "./styles.module.css";
import borde from "../../../assets/recuadro-dorado.svg";
import pierRojos from "../../../assets/Cigarillos-Corona-View-06.png";
import Footer from "../../../components/footer/Footer.jsx";
import band1 from "../../../assets/Corona/band_sant.png";
import band2 from "../../../assets/Corona/band_arg.png";
import specialBox from "../../../assets/Cigarillos-Corona-View-05.png";
import redFlag from "../../../assets/bandera-roja.svg";



export default function Corona() {
  return (
    <>
      <div className={styles.firstSection}>
        <div className={styles.flexColum}>
        </div>

        <div className={styles.contenedor}>
          <div className={styles.containerFaltante}>
            <img
              src={redFlag}
              alt="CORONA"
              className={styles.redFlag}
            />
            <img
              src={specialBox}
              alt="CORONA"
              className={styles.specialBox}
            />
          </div>
        </div>

      </div>
      <div className={styles.goldenSeparator} />
      <div className={styles.sixthSection}>
        <div className={styles.conteinerProductSixthSection}>
          <div className={styles.contenedorBorde}>
            <img
              src={borde}
              loading="lazy"
              alt="borde"
              className={styles.borde}
            />
          </div>
          <img
            src={pierRojos}
            alt="DOLCHESTER ORIGINAL BLEND"
            className={styles.pierBoxes}
          />
          <div className={styles.textContainerLeft}>
            <h2 className={styles.titlefifthSection}>CORONA</h2>
            <p className={styles.subtitlefifthSection}>
              En el marco de su compromiso con la innovación y la calidad, REAL TABACALERA de
              Santiago se enorgullece de presentar su primer producto: CORONA.
            </p>
            <p className={`${styles.subtitlefifthSection} ${styles.spaceSubtitle}`}>
              Resultado de un gran esfuerzo en investigación y desarrollo, CORONA busca ofrecer un
              sabor local premium basado en un blend clásico de tabaco de alta calidad. Este producto
              combina calidad y tecnología, capturando la esencia del tabaco de la región y llevándola
              a los consumidores más exigentes.
            </p>
            <p className={`${styles.subtitlefifthSection} ${styles.spaceSubtitle}`}>
              CORONA promete establecer un nuevo estándar en el mercado, destacándose por su
              sabor distintivo y su alta calidad.
            </p>
            <p className={styles.details}>BOX x20</p>
          </div>
        </div>
      </div>
      <div className={styles.thirdSection}>
        <div className={styles.text}>
          <h2 className={styles.title}>CORONA UNA MARCA 100% ARGENTINA</h2>
          <div>
            <p className={styles.subtitle}>
              Desde Santiago del Estero creamos productos Argentinos, que compiten con
              las grandes marcas internacionales. Estamos enfocados en satisfacer las exigencias
              de un mercado cada vez más competitivo imponiendo siempre productos
              de Industria Nacional.
            </p>
          </div>
          <div className={styles.band}>
            <img src={band1} alt="Bandera" />
            <img src={band2} alt="Bardera" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
