import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// pages
import NotFound from "./pages/NotFound.jsx";
import Login from "./pages/login/Login.jsx";
import Inicio from "./pages/inicio/Inicio.jsx";
import Empresa from "./pages/Empresa/index.jsx";
import Registro from "./pages/registro/Registro.jsx";
import Politicas from "./pages/politicas-privacidad/Politicas.jsx";

import { PrivateRoute } from "./utils/PrivateRoute.jsx";
import Dashboard from "./pages/admin/index.jsx";
import ScrollToTop from "./components/scrollToTop/ScrollToTop.jsx";
import Pier from "./pages/Productos/Pier/Pier.jsx";
import Dolchester from "./pages/Productos/Dolchester/Dolchester.jsx";
import Corona from "./pages/Productos/Corona/Corona.jsx";
import Contacto from "./pages/contacto/Contacto.jsx";
import PierAndRoll from "./pages/Productos/Pier&Roll/Pier&Roll.jsx";
import Legales from "./pages/legales/Legales";
import Liverpool from "./pages/Productos/Liverpool/Liverpool.jsx";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/politicas-privacidad" element={<Politicas />} />
        <Route path="/legales" element={<Legales />} />
        <Route path="/dashboard" element={<Dashboard />} />


        {/* RUTAS PROTEGIDAS */}
        <Route element={<PrivateRoute />}>
          <Route path="/inicio" element={<Inicio />} />
          <Route path="/Pier&Roll" exact element={<PierAndRoll />} />
          <Route path="/Pier" exact element={<Pier />} />
          <Route path="/Liverpool" exact element={<Liverpool />} />
          <Route path="/Dolchester" element={<Dolchester />} />
          <Route path="/corona" element={<Corona />} />
          <Route path="/empresa" element={<Empresa />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="*" element={<Inicio />} />
        </Route>

        {/* RUTAS ADMIN */}
        <Route element={<PrivateRoute />}>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
