import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./usersSlice.js";

// Configura la persistencia de datos
const persistConfig = {
  key: "root",
  storage,
};

// Combina el reducer persistido con tu reducer existente
const persistedReducer = persistReducer(persistConfig, userReducer);

// Crea el store Redux
export const store = configureStore({
  reducer: persistedReducer,
});

// Crea el persistor para manejar la persistencia
export const persistor = persistStore(store);
