import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../components/navBar/Navbar.jsx";
import CookiesModal from "../components/cookies/CookiesModal.jsx";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

export const PrivateRoute = ({ children }) => {
  const { pathname } = useLocation();
  //const isAdmin = useSelector((state) => state.usuario.isAdmin);
  const isAdmin = true;

  // const getLoggedHour = JSON.parse(localStorage.getItem("dateLogin"));
  // const loggedDate = moment(getLoggedHour);
  // var nextDay = loggedDate.add(1, "day").hour(4).minute(0).second(0);
  // var actualDate = moment();
  // const isLogged = !actualDate.isAfter(nextDay);

  //if (!isLogged || !getLoggedHour) return <Navigate to="/" />


  //esto es parte de lo nuevo
  const acceptedConditions = sessionStorage.getItem('acceptedConditions');
  const isAccepted = acceptedConditions === 'true';

  // console.log('En la private: ', isAccepted)


  // if (!isAdmin && pathname === "/dashboard" && isAccepted) {
  //   return <Navigate to="/dasdasd" replace={true} />; // Redireccionar al componente de inicio de sesión
  // }

  if (!isAdmin && pathname === "/dashboard" && isAccepted) {
    return <Navigate to="/" replace={true} />;
  }

  if (!isAccepted) {
    return <Navigate to="/" replace={true} />;
  }
  return (
    <>
      <Navbar />
      <CookiesModal />
      {children ? children : <Outlet />}
    </>
  );
};
