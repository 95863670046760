import React from 'react'

export default function Politicas() {
  return (
    <div>
        POLÍTICA DE PRIVACIDAD

1. INFORMACIÓN DEL SITIO/GENERALIDADES

La presente Política de Privacidad (en adelante, la “Política”) se aplica a la utilización de los datos personales de cualquier persona que visite o se registre (en adelante, el “Usuario”) en la URL: https://www.digitalhouse.com/ar (en adelante, el “Sitio”), provista por TABAQUERA S.A. (en adelante, “TABAQUERA”).

El Sitio tiene la función principal de ofrecer al Usuario información relacionada a las capacitaciones que TABAQUERA provee bajo una metodología innovadora basada en la práctica a través de determinados programas, tales como, pero sin limitar: Programación Web Full Stack, Diseño de Experiencia de Usuario (UX), Data Science, Data Analytics, Marketing Digital, entre otros (en adelante, el “Servicio”).

En caso de ser necesario, TABAQUERA podrá complementar esta Política con información y/o términos y condiciones específicos con relación al Servicio.

AL ACCEDER, CONECTARSE O DE CUALQUIER OTRA FORMA UTILIZAR EL SITIO, EL USUARIO CONFIRMA QUE LEYÓ, ENTENDIÓ, CONSENTIÓ Y ACEPTÓ LOS TÉRMINOS Y CONDICIONES DE ESTA POLÍTICA DE PRIVACIDAD. SI NO ESTÁ DE ACUERDO CON LOS TÉRMINOS DE ESTA POLITICA DE PRIVACIDAD, EL USUARIO NO DEBE UTILIZAR EL SITIO NI LOS CANALES DE COMUNICACIÓN DE TABAQUERA.

2. CONTENIDO

2.1. Entre otras cuestiones, esta Política describe: (i) quién es TABAQUERA y qué es lo que hace; (ii) de qué manera comunicarse con TABAQUERA; (iii) qué datos personales recolecta TABAQUERA; (iv) cuál es el tratamiento que se hace de los datos personales y las finalidades del mismo; (v)  con quién se comparte la información personal; (vi) transferencia de datos personales fuera de Argentina; (vii) Qué medidas toma TABAQUERA para garantizar la seguridad y confidencialidad de los datos; (viii) los derechos que le asisten al Usuario.

3. RESPONSABLE DE TRATAMIENTO DE SUS DATOS PERSONALES

3.1. TABAQUERA será la sociedad responsable del tratamiento de los Datos Personales que el Usuario facilite en el Sitio, o que se recopilen o procesen en el Sitio. En cualquier caso, cualesquiera recopilación, uso e información compartida en relación con sus Datos Personales, el Usuario quedará sujeto a esta Política, sus actualizaciones y la Política de Cookies complementaria.

3.2. De acuerdo con los términos de la Ley de Protección de Datos Personales Nº 25.326 (en adelante, la “LPDP”) y normativa complementaria, se le informa al Usuario que los datos recolectados forman parte de una base de datos de TABAQUERA, con domicilio en Monroe 860, CABA, debidamente registrada ante la Agencia de Acceso a la Información Pública (en adelante, “AAIP”).

3.3. Para TABAQUERA, la privacidad de los Datos Personales del Usuario es muy importante. En caso de que el Usuario tenga alguna duda acerca de la Política o para ejercer los derechos más adelante expuestos (punto 12), podrá ponerse en contacto con TABAQUERA en cualquier momento, enviando un correo electrónico a legales@digitalhouse.com. 

4. BASES LEGALES

4.1. TABAQUERA procesa la información personal del Usuario sobre las siguientes bases legales, autorizadas bajo la LPDP y regulación complementaria: (i) consentimiento libre, expreso e informado de los titulares de datos; (ii) cumplimiento de todo acuerdo contractual con los titulares de datos; (iii) cumplimiento de obligaciones legales; (iv) intereses legítimos autorizados por la LPDP.

5. QUÉ DATOS PERSONALES SE RECOLECTAN

5.1. A continuación se detalla toda la información que TABAQUERA recolecta:

-          Información facilitada por el Usuario: i) nombre y apellido; (ii) teléfono, (iii) estado de residencia, (iv) e-mail y (v) curso.

-          Información recopilada pasivamente: Cuando el Usuario accede al Sitio y/o utiliza el Servicio, TABAQUERA podrá recopilar y almacenar de forma automática cierto tipo de información, tales como la dirección del protocolo de Internet (IP) de la computadora o dispositivo del Usuario y otra información técnica sobre el uso de la computadora o dispositivo, como el tipo y versión del navegador, la configuración de la zona horaria, y el sistema operativo, ubicaciones geográficas, sesiones de uso, permanencia en el Sitio, frecuencia de uso.

-          Información proveniente de cookies y/o cualquier otro método de detección de información automatizada provisto por las herramientas que ofrecen en el Sitio: La información que recopile TABAQUERA podrá incluir el comportamiento de navegación, dirección IP, logs, y otros tipos de información. Sin embargo, TABAQUERA no recolectará información personal identificable de manera directa de ningún Usuario usando cookies o cualquier otro método de detección de información automatizada provisto por las herramientas que ofrece el Sitio.

 6. FINALIDAD DE TRATAMIENTO DE LOS DATOS PERSONALES

6.1. TABAQUERA trata la información personal del Usuario para las siguientes finalidades:

-          Autorizar y administrar el acceso del Usuario al Sitio, verificar su identidad, autenticar sus visitas y proporcionarle el Servicio;

-          Gestionar, analizar, desarrollar, personalizar y mejorar el Sitio y el Servicio;

-          Análisis a efectos de poder brindar al Usuario el Servicio, y mejorar el método de pago;

-          Enviar al Usuario notificaciones y novedades sobre la actualidad de TABAQUERA, sus servicios y eventos a través cualquier medio, incluso cuando la relación haya terminado, salvo que la persona interesada manifieste lo contrario; 

-          Facilitar el cumplimiento de obligaciones legales en caso de ser solicitadas por tribunales, u organismos estatales nacionales o internacionales que así lo requieran y lo soliciten en la forma correspondiente. 

-          Obtener el diagnóstico de los eventuales problemas de conexión que puedan llegar a existir entre el Sitio y el Usuario, mejorando la calidad de los servicios.

7. CON QUIÉN SE COMPARTE LA INFORMACIÓN PERSONAL

7.1. Jamás venderemos tu información personal sin tu consentimiento. Tampoco revelaremos a terceros tu información personal sin tu consentimiento, con las únicas excepciones de los supuestos previstos en esta Política.

7.2. Podemos compartir tus datos personales con los siguientes terceros bajo las circunstancias detalladas debajo, siempre de conformidad con lo establecido en el Artículo 11 de la LPDP:

-          Con entidades relacionadas a TABAQUERA.

-          Con proveedores de servicios, siguiendo las instrucciones de TABAQUERA.

-          Con autoridades gubernamentales o reguladoras, Juzgados, Tribunales u otras entidades u oficinas públicas, si lo consideramos necesario, apropiado u obligatorio en virtud de la legislación o regulación aplicable.

-          Con cualquier nueva entidad de TABAQUERA u otro tercero en caso de venta o fusión de TABAQUERA, o de una adquisición de los activos principales de TABAQUERA, o cualquier otra clase de transferencia de TABAQUERA a otra entidad.

7.3.En lo que respecta al tratamiento de datos personales de ciudadanos de la Unión Europea, TABAQUERA estará a los principios y obligaciones recogidas en el Reglamento UE 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (RGPD).

7.4. El Sitio podrá contener enlaces a otros sitios de internet que no sean propiedad de TABAQUERA. En consecuencia, TABAQUERA no será responsable por el actuar de dichos sitios de internet, a los cuales no se aplicará la presente Política. Recomendamos examinar la política de privacidad detallada en aquellos sitios de internet para entender los procedimientos de recolección de información que utilizan y como protegen sus datos personales.

8. TRANSFERENCIA INTERNACIONAL

8.1. El Usuario declara comprender y consentir que sus datos personales pueden ser transferidos a países o jurisdicciones que pueden no tener el mismo nivel de normativa de protección legal de datos personales que el que existe en Argentina y, en consecuencia, contar con una menor protección. Cuando TABAQUERA transfiere los datos personales a otros países, los protege en la forma descripta en este documento, implementando salvaguardas que garantizan un nivel adecuado de protección de los datos que se transfieren fuera de la Argentina.

8.2. En lo que respecta al tratamiento de datos personales de ciudadanos de la Unión Europea (UE) y/o Espacio Económico Europeo, se informa que:

(i) TABAQUERA podrá transferir los datos personales del Usuario del Sitio y los que se generen, en su caso, al utilizar el Sitio y/o el Servicio a entidades situadas en el territorio de la República Argentina, siendo éste, un país declarado por la Comisión Europea de nivel de protección adecuada conforme a su Decisión 2003/490/CE, de 30 de junio de 2003.

(ii) TABAQUERA podrá transferir los referidos Datos Personales a otras entidades situadas en terceros países que hayan sido declarados por la Comisión Europea de nivel de protección adecuada o con las que se hayan suscrito acuerdos con las garantías adecuadas impuestas por las autoridades europeas.

9. CONFIDENCIALIDAD Y SEGURIDAD DE LA INFORMACIÓN

9.1. TABAQUERA ha adoptado medidas de seguridad razonables para proteger la información de del Usuario e impedir el acceso no autorizado a sus datos o cualquier modificación, divulgación o destrucción no autorizada de los mismos, de acuerdo con la LPDP. La información recolectada por TABAQUERA será mantenida de manera estrictamente confidencial. El acceso a los datos personales está restringido a aquellos empleados, contratistas, operadores, y representantes de TABAQUERA que necesitan conocer tales datos para desempeñar sus funciones y desarrollar o mejorar nuestro Servicio. TABAQUERA exige a sus proveedores los mismos estándares de confidencialidad. TABAQUERA no permite el acceso a esta información a terceros ajenos a TABAQUERA, a excepción de un pedido expreso del Usuario.

9.2. Sin perjuicio de lo expuesto, considerando que internet es un sistema abierto, de acceso público, TABAQUERA no puede garantizar que terceros no autorizados no puedan eventualmente superar las medidas de seguridad y utilizar la información del Usuario en forma indebida. En todo caso, TABAQUERA mantiene planes de seguridad y de respuesta a incidentes para controlar incidentes relacionados con el acceso no autorizado a la información privada que recopila o almacena.

10. CONSERVACIÓN DE LA INFORMACIÓN

10.1. TABAQUERA conservará los datos personales por el tiempo que sea necesario para el cumplimiento de las finalidades por las que fueron recolectados, de conformidad con el Art. 4 de la LPDP. Ciertas categorías de datos personales pueden ser conservadas después de que la finalidad principal del tratamiento haya sido cumplida: (i) por un plazo legal determinado en alguna norma aplicable; (ii) por el plazo de la prescripción para emprender acciones civiles, administrativas o penales, con el fin de asegurar el derecho de defensa de TABAQUERA.

11. MENORES DE EDAD 

11.1. El Sitio no está dirigido a menores de 18 años. Si el Usuario es menor de 18 años, no debe utilizar el Sitio ni proporcionar sus datos personales, salvo bajo autorización y consentimiento expreso de sus padres y/o representantes legales.

12. DERECHOS DEL USUARIO SOBRE LA INFORMACIÓN

12.1. De conformidad con los Artículos 14, 15 y 16 de la LPDP, el Usuario tiene los siguientes derechos con relación a sus datos personales:

-          Solicitar el acceso a sus datos.

-          Rectificar datos incompletos o inexactos que se relacionen con su persona.

-          Pedirle a TABAQUERA que elimine sus datos cuando no haya base legal para que sigan procesándolos.

-          Retirar cualquier consentimiento que haya proporcionado para el tratamiento de tus datos personales.

12.2. Para ejercer cualquiera de los derechos expuestos, el Usuario puede contactarse con TABAQUERA enviando un correo electrónico a legales@digitalhouse.com, indicando en el asunto “Ley N° 25.326 – Ejercicio derechos datos personales”.

12.3. Es posible que, en ciertos casos, TABAQUERA deniegue las solicitudes si necesita preservar los datos para prestar un determinado servicio, proteger la seguridad o la funcionalidad de sus operaciones, cumplir con una obligación legal o reglamentaria o por otros intereses legítimos.

12.4. En lo que respecta al Usuario que sea ciudadano de la Unión Europea, éste podrá ejercer los siguientes derechos frente a TABAQUERA:

-          Acceso: tiene derecho a acceder a su información para conocer qué datos personales en concreto TABAQUERA está tratando.

-          Rectificación: tiene derecho a rectificar aquellos datos personales inexactos que TABAQUERA está tratando.

-          Supresión: tiene derecho a solicitar la supresión de aquellos datos personales que no desee que TABAQUERA siga tratando.

-          Oposición: en determinadas circunstancias, y por motivos relacionados con su situación particular, tiene derecho a oponerse a que TABAQUERA trate sus datos personales, en cuyo caso se mantendrán bloqueados durante el plazo descrito anteriormente.

-          Limitación del tratamiento: tiene derecho a solicitar la limitación del tratamiento de sus datos personales a aquellas finalidades concretas que desee.

-          Portabilidad: tiene derecho a recibir aquellos datos personales que le hubiera facilitado a TABAQUERA, en un formato estructurado, de uso común y lectura mecánica, y a que se los transmitan a otro responsable del tratamiento distinto de TABAQUERA.

Para ejercer cualquiera de ellos deberá proceder al envío de un correo electrónico a TABAQUERA a la siguiente dirección legales@digitalhouse.com en el que adjunte una copia de su documento de identidad o Pasaporte.  Si lo considera oportuno, el Usuario también puede presentar un reclamo ante la autoridad de control correspondiente.

13. DENUNCIAS

En cumplimiento de la Resolución AAIP Nº 14/2018, le hacemos saber al Usuario que la Agencia de Acceso a la Información Pública, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.

14. MODIFICACIÓN DE LA POLÍTICA DE PRIVACIDAD

Esta Política está sujeta a cambios y actualizaciones. El Usuario debe revisar la Política regularmente para asegurarse de que está familiarizado con su contenido.

La fecha de esta Política de Privacidad es septiembre de 2022.
    </div>
  )
}
