import React, { useState } from "react";
import styles from "./styles.module.css";

function ChangeVideo({ videos, handleSubmit }) {
  const [updatedVideos, setUpdatedVideos] = useState(videos);
  const [videoInputs, setVideoInputs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (index, value) => {
    const updatedInputs = [...videoInputs];
    updatedInputs[index] = value;
    setVideoInputs(updatedInputs);
  };

  const onSubmit = async (index) => {
    setIsLoading(true);
    const inputValue = videoInputs[index];
    try {
      const { success, message } = await handleSubmit(index++, inputValue);
      if (!success) {
        throw new Error(message);
      }
      if (success) {
        const updatedVideo = { ...updatedVideos[index], link: inputValue };
        const updatedVideosList = [...updatedVideos];
        updatedVideosList[index] = updatedVideo;
        setUpdatedVideos(updatedVideosList);
      }
    } catch (error) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage("");
      }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  if (!videos.length) return null;

  return (
    <div>
      {videos.map((_video, index) => {
        let videoTitle = "";
        if (index === 0) {
          videoTitle = "Video de Inicio";
        } else if (index === 1) {
          videoTitle = "Video de Pier";
        } else if (index === 2) {
          videoTitle = "Video de Pier&Roll";
        } else if (index === 3) {
          videoTitle = "Video de Dolchester";
        } else if (index === 4) {
          videoTitle = "Video de J&B";
        }

        return (
          <div key={index}>
            <h3>{`Video Numero ${index + 1}`}</h3>
            <p>{videoTitle}</p>
            <p>Formato correcto: "https://www.youtube.com/embed/fMoigqdshEY"</p>

            <div className={styles.divActual}>
              <p className={styles.actualUrl}>
                Video Actual - Ingresar "sin video" para remover
              </p>
              {updatedVideos[index]?.link ? (
                <a
                  className={styles.actualVideo}
                  target="_blank"
                  rel="noreferrer"
                  href={updatedVideos[index]?.link}
                >
                  {updatedVideos[index]?.link}
                </a>
              ) : (
                <p>No hay video ingresado</p>
              )}
            </div>
            <div className={styles.divNewVideo}>
              <input
                placeholder="Nuevo link del video"
                className={styles.newUrl}
                type="text"
                name={`video${index++}`}
                value={videoInputs[index] || ""}
                onChange={(e) => handleInputChange(index, e.target.value)}
              />
              <div className={styles.containerButton}>
                <button
                  onClick={() => onSubmit(index)}
                  className={styles.button}
                  type="button"
                  disabled={isLoading}
                >
                  {isLoading ? "Cargando..." : "Cambiar"}
                </button>
              </div>
            </div>
            <div className={styles.errorMessage}>
              {errorMessage && <p>{errorMessage}</p>}
            </div>
          </div>
        );
      })}
    </div>
  );

}


export default ChangeVideo;
