import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./login.module.css";
import body from "../../assets/background-inicio.jpeg";
import ModalEmail from "../../components/modal/ModalEmail.jsx";
import CookiesModal from '../../components/cookies/CookiesModal.jsx';
import logo from "../../assets/bronway.svg";

export default function Login() {
  const navigate = useNavigate();
  const [acceptedConditions, setAcceptedConditions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalReset, setShowModalReset] = useState(false);
  const [showSaludo, setShowSaludo] = useState(true);

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  const handleModalToggleEmail = () => {
    setShowModalReset(!showModalReset);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseModalReset = () => {
    setShowModalReset(false);
  };

  const handleChange = (event, setter) => {
    const { checked } = event.target;
    sessionStorage.setItem('acceptedConditions', checked.toString());

    setter(
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value
    );
  };

  const toHome = () => {
    if (!acceptedConditions) {
      return alert("Debe aceptar los los términos y condiciones para acceder.");
    }

    navigate("/inicio");

  }


  return (
    <div className={styles.login}>
      <img
        src={body}
        alt="imagen de fondo login"
        className={styles.background}
        loading="lazy"
      />

      <form action="" className={styles.loginForm}>

        <img src={logo} alt="logo" className={styles.logoLogin} />

        {showSaludo ? (
          <>
            <h3>¿SOS MAYOR DE 18 AÑOS?</h3>

            <div className={styles.esMayor}>
              <h4 onClick={() => setShowSaludo(false)}>NO, SALIR</h4>
              <h4 onClick={() => toHome()}>SI, SOY MAYOR</h4>
            </div>

            <div className={styles.conditions}>
              <input
                type="checkbox"
                id="conditions"
                checked={acceptedConditions}
                onChange={(e) => handleChange(e, setAcceptedConditions)}
              />
              <label htmlFor="conditions">
                Estoy de acuerdo con los términos y condiciones descriptos abajo y
                con la política de privacidad de Real Tabacalera.
              </label>
            </div>
          </>
        ) : (
          <h5 className={styles.gracias}>Gracias</h5>
        )}



      </form>
      <div className={styles.goldenBar}></div>

      <div className={styles.terminos}>
        <p>
          Sólo podrán acceder aquellos fumadores mayores de 18 años que residan en la República Argentina. Al proporcionar tus datos podemos validarlos y asegurar su cumplimiento. REAL TABACALERA DE SANTIAGO por ley y política de responsabilidad asume el compromiso de comunicarse exclusivamente con fumadores adultos mayores de 18 años, residentes en la República Argentina pertenecientes a su base de datos. (Los datos serán verificados por un tercero y/o constatados a través de páginas web de información de público acceso). REAL TABACALERA DE SANTIAGO confeccionará una base de datos de fumadores adultos con la finalidad de poder remitirles comunicaciones, información, publicidad y materiales promocionales referentes a productos de tabaco y asuntos relacionados con el tabaco (Art. 6 Ley 25.326). El titular de los datos personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326. La DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales (Disposición 10/08).
        </p>
      </div>

      <div className={styles.linksLegales}>
        <p onClick={() => navigate('/legales')}>legales</p>
        <p onClick={() => navigate('/legales')}>condiciones</p>
        <p onClick={handleModalToggle}>salir de la base de datos</p>
      </div>
      <CookiesModal />

      <ModalEmail showModal={showModal} text={'Ingresá tu email para salir de la base de datos: '} reset={false} onClose={handleCloseModal} />

      <ModalEmail showModal={showModalReset} text={'Ingresá tu email para reestablecer tu contraseña y revisá tu casilla de correo: '} reset={true} onClose={handleCloseModalReset} />
    </div>
  );
}
