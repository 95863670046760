import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import moment from "moment";
import imgPier2 from "../../../assets/Pier_Roll_Armado_07.png";
import pierRoll from "../../../assets/Recurso-1.svg";
// import separador from "../../../assets/separador.svg";
// import tabacoRubio from "../../../assets/tabacoRubio.svg";
// import tabacoNatural from "../../../assets/tabacoNatural.svg";
import borde from "../../../assets/borde.svg";
import Marcas from "../../../components/marcas/Marcas.jsx";
import styles from "./styles.module.css";
import Footer from "../../../components/footer/Footer.jsx";

export default function PierAndRoll() {
  const [video, setVideo] = useState("");
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const response = await fetch(
        "https://api-tabaquera-psi.vercel.app/api/videos"
      );
      const json = await response.json();
      setVideo(json[2].link);
    } catch (error) { }
  };

  // useEffect(() => {
  //   const getLoggedHour = JSON.parse(localStorage.getItem("dateLogin"));
  //   const loggedDate = moment(getLoggedHour);
  //   var nextDay = loggedDate.add(1, "day").hour(4).minute(0).second(0);
  //   var actualDate = moment();
  //   if (actualDate.isAfter(nextDay) || !getLoggedHour) {
  //     localStorage.removeItem("dateLogin");
  //     navigate("/");
  //   }
  //   getData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <>
      {video !== "sin video" && video ? (
        <iframe
          className={styles.video}
          src={video}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen>
        </iframe>
      ) : (
        <section className={styles.section1}>
          <div />
          <div className={styles.rightSide}>
            <img
              src={pierRoll}
              loading="lazy"
              alt="lillos inicio"
              className={styles.pierRoll}
            />
            {/* <h2 className={styles.imgSub}>
              PIER & ROLL PAPEL DE ARMAR CLÁSICO Y LIBRE DE CLORO.
            </h2> */}
          </div>
        </section>
      )}

      <section className={styles.section2}>
        <div className={styles.contenedor}>
          <div className={styles.contenedorBorde}>
            <img
              src={borde}
              loading="lazy"
              alt="borde"
              className={styles.borde}
            />
          </div>
          <div className={styles.contenedorInterno}>
            <div className={styles.izquierda}>
              <img
                src={imgPier2}
                loading="lazy"
                alt="imagen de fondo login"
                className={styles.lillosPierArmado}
              />
              <div className={styles.contenedorTexto}>
                {/* <h2>PAPEL DE ARMAR CLÁSICO</h2>
                <p>
                  Papel de armar 100% natural. <br></br>Producido con papel
                  ultrafino de alta calidad.
                </p>
                <p>50 HOJAS | CAJA EXHIBIDORA X50</p>

                <h2>PAPEL DE ARMAR ORGÁNICO</h2>
                <p>
                  Papel de armar100% natural, orgánico. <br></br>Producido con
                  papel ultrafino de alta calidad, <br></br>sin cloro agregado
                  en el proceso.
                </p>
                <p>50 HOJAS | CAJA EXHIBIDORA X50</p> */}
                <h2 className={styles.subtitle}>PIER & Roll. El momento lo armás vos.</h2>
                <p>
                  Los buenos momentos no son responsabilidad del destino, no hay que esperar a que se alineen los planetas para pasarla bien. Hay que estar decidido, juntar a los amigos y tener un buen plan.
                </p>
                <p>La actitud es protagonista, lo mejor está por venir.</p>
                <hr className={styles.divider} />
                <p>
                  Los papeles PIER&ROLL proponen una experiencia única. Podés encontrarlos en su variable Clásico de papel blanco o en su variable Orgánica libre de cloro.
                </p>
              </div>
            </div>

            {/* <div className={styles.contenedorSeparador}>
              <img
                src={separador}
                loading="lazy"
                alt="separador"
                className={styles.separador}
              />
            </div>

            <div className={styles.derecha}>
              <div className={styles.contenedorTabacos}>
                <img
                  src={tabacoRubio}
                  loading="lazy"
                  alt="tabaco Rubio"
                  className={styles.lillos}
                />
                <img
                  src={tabacoNatural}
                  loading="lazy"
                  alt="tabaco Natural"
                  className={styles.lillos}
                />
              </div>
              <div className={styles.contenedorTexto}>
                <h2>PIER ORIGINAL RED BLEND</h2>
                <p>
                  El clásico cigarrillo PIER descubriendo un nuevo horizonte.
                  <br></br> Un diseño evolucionado potenciando al mismo blend de
                  calidad de tabaco Virginia.
                </p>
                <p>BOX x10 | BOX x20</p>

                <h2>PIER GREEN BLEND</h2>
                <p>
                  El mismo cigarrillo de siempre en su variante Menthol.{" "}
                  <br></br>Un aire de frescura para el mismo blend innovador.{" "}
                  <br></br> La evolución de una imagen que abraza al mismo
                  sabor.
                </p>
                <p>BOX x10 | BOX x20</p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <Marcas />

      <Footer></Footer>
    </>
  );
}
