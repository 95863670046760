import React, { useState } from "react";
import styles from "./footer.module.css";
import logo from "../../assets/Corona/logo_real_tabacalera.png";
import advertencia from "../../assets/advertencia.jpg";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import { newsletterCredentials } from "../../utils/emailCredentias.js";
import {
  validateEmail,
} from "../../utils/validations";

export default function Footer() {
  const [sent, setSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ resendEmail, resendPhone, ...rest }) => {
    setIsLoading(true);
    const { serviceId, templateId, acountId } = newsletterCredentials;
    const template = {
      subs_email: rest.email
    }
    await emailjs
      .send(serviceId, templateId, template, acountId)
      .then((response) => {
        alert("Gracias por escribirnos. Recibimos tu mensaje correctamente.");
      })
      .catch((error) => {
        setErrorMessage("Ocurrio un error al enviar el mensaje");
        return setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      })
      .finally(() => {
        setIsLoading(false);
        setSent(true);
      });
  };


  return (
    <footer className={styles.footer}>
      <div className={styles.goldenBar}></div>
      <img src={logo} alt="logo" className={styles.logo} />
      <div className={styles.container1}>
        <div className={styles.container}>
          <div>
            <h4>Planta Industrial</h4>
            <p>Ruta Provincial 5,</p>
            <p>Km 2,2. La Banda (4300)</p>
            <p>Santiago Del Estero</p>
            <p>Argentina</p>
          </div>
          <div>
            <h4>Contacto Comercial</h4>
            <p>Rodrigo Piaggio</p>
            <p>ventas@realtabacalera.com</p>
          </div>
        </div>
        <div className={styles.middleContainer}>
        </div>

        <div className={styles.newslatter}>
          <form onSubmit={handleSubmit(onSubmit)} className={styles.loginFormFooter}>
            <h4>NEWSLETTER</h4>
            <p>Suscribite para recibir nuestras novedades</p>
            <div className={styles.flexForm}>
              <div className={styles.loginFormBody}>
                <div className={styles.loginFormInput}>
                  <label htmlFor="email">Tu email</label>
                  <input
                    id="email"
                    className={styles.inputEmail}
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className={styles.error}>
                      Este campo es requerido
                    </span>
                  )}
                  {errors.email && errors.email.type !== "required" && (
                    <span className={styles.error}>{errors.email.message}</span>
                  )}
                </div>
              </div>

              {!sent ?
                <button type="submit" className={styles.button}>
                  {isLoading ? "Cargando" : "SUSCRIBIRME"}
                </button>
                :
                <button type="button" disabled className={styles.button}>
                  SUSCRIPTO
                </button>
              }
              <p className={styles.errorMessage}>{errorMessage}</p>
            </div>
          </form>
        </div>
      </div>

      <div className={styles.footerAdvertencia}>
        <img
          src={advertencia}
          alt="advertencia"
          className={styles.advertencia}
        />
      </div>
    </footer>
  );
}
