import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import moment from "moment";
import ChangeVideo from "../../components/ChangeVideo/index.jsx";
import { useDispatch } from "react-redux";
import { loginUser } from "../../redux/usersSlice.js";
import { set } from "react-hook-form";

export default function Dashboard() {
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [email, setEmail] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [errorEmailMessage, setErrorEmailMessage] = useState("");
  const navigate = useNavigate();
  const usuario = useSelector((state) => state.usuario);
  const [videos, setVideos] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState("");
  const [credentialError, setCredentialError] = useState(false);
  const [dni, setDni] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [acceptedConditions, setAcceptedConditions] = useState(false);

  useEffect(() => {
    // const getLoggedHour = JSON.parse(localStorage.getItem("dateLogin"));
    // const loggedDate = moment(getLoggedHour);
    // var nextDay = loggedDate.add(1, "day").hour(4).minute(0).second(0);
    // var actualDate = moment();
    // if (actualDate.isAfter(nextDay) || !getLoggedHour) {
    //   localStorage.removeItem("dateLogin");
    //   navigate("/");
    // }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    try {
      const response = await fetch(
        "https://api-tabaquera-psi.vercel.app/api/videos"
      );
      const json = await response.json();
      setVideos(json);
      setLoading(false);
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  const handleSubmit = async (id, video) => {
    if (video === "sin video") {
      video = ""
    } else {
      video = video + "?autoplay=1&mute=1&controls=0"
    }
    setLoadingVideo(true);
    const response = await fetch(
      `https://api-tabaquera-psi.vercel.app/api/video-by-id`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id,
          link: video,
          dni: usuario.dni,
        }),
      }
    );
    if (
      response.status === 500 ||
      response.status === 404 ||
      response.status === 401 ||
      response.status === 400
    ) {
      setLoadingVideo(false);
      setErrorMessage("Ocurrio un error al cambiar el video");
      setTimeout(() => {
        setErrorMessage("");
      }, 10000);
      return { message: errorMessage, success: false };
    }
    setLoadingVideo(false);
    return { message: "", success: true };
  };

  const handleDeleteEmail = async () => {
    setLoadingEmail(true);
    const response = await fetch(`https://api-tabaquera-psi.vercel.app/api/users`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        dni: usuario.dni,
      }),
    });
    if (response.status === 404) {
      setErrorEmailMessage("Email no encontrado");
      setLoadingEmail(false);
      return setTimeout(() => {
        setErrorEmailMessage("");
      }, 10000);
    }
    if (response.status === 500) {
      setErrorEmailMessage("Ocurrio un error al eliminar el Email");
      setLoadingEmail(false);
      return setTimeout(() => {
        setErrorEmailMessage("");
      }, 10000);
    }
    if (response.status === 400 || response.status === 401) {
      setErrorEmailMessage("No está autorizado");
      setLoadingEmail(false);
      return setTimeout(() => {
        setErrorEmailMessage("");
      }, 10000);
    }
    setLoadingEmail(false);
  };

  const dispatch = useDispatch();

  const handleSubmitLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (!acceptedConditions) {
      return alert("Debes aceptar las condiciones para iniciar sesión.");
    }
    const { payload, meta } = await dispatch(loginUser({ dni, password }));
    if (payload.isLogged) {
      setIsLoading(false);
      localStorage.setItem('dateLogin', JSON.stringify(moment().format()));
      //return payload.isAdmin ? navigate("/dashboard") : navigate("/inicio");

      payload.isAdmin ? setIsAdmin(true) : setIsAdmin(false);
    }
    if (meta.rejectedWithValue) {
      setIsLoading(false);
      setErrorMessage("Credenciales incorrectas");
    } else {
      setIsLoading(false);
      setErrorMessage("Ocurrio un error");
    }
    return setTimeout(() => {
      setErrorMessage("");
    }, 5000);
  };

  const handleChange = (event, setter) => {
    const { checked } = event.target;
    sessionStorage.setItem('acceptedConditions', checked.toString());

    setter(
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value
    );
  };

  if (loading) {
    return (
      <div className={styles.container}>
        <p className={styles.texto}>Cargando...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.container}>
        <p className={styles.texto}>
          Ocurrio un error al cargar, intente recargando la página.
        </p>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <form onSubmit={handleSubmitLogin} className={styles.loginForm}>
        <h3>Ingresá con tu dni</h3>
        <div className={styles.loginFormBody}>
          <div className={styles.loginFormInput}>
            <label htmlFor="dni">dni</label>
            <input
              type="number"
              id="dni"
              value={dni}
              onChange={(e) => handleChange(e, setDni)}
              required
            />
          </div>
          <div className={styles.loginFormInput}>
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => handleChange(e, setPassword)}
              required
            />
          </div>
          <div className={styles.conditions}>
            <input
              type="checkbox"
              id="conditions"
              checked={acceptedConditions}
              onChange={(e) => handleChange(e, setAcceptedConditions)}
            />
            <label htmlFor="conditions">
              Estoy de acuerdo con los términos y condiciones descriptos abajo y
              con la política de privacidad de Real Tabacalera.
            </label>
          </div>
        </div>
        <p className={styles.errorMessage}>{errorMessage}</p>

        <button type="submit" className={styles.button}>
          {isLoading ? "Cargando..." : "ingresar"}
        </button>

      </form>
    )
  }

  if (credentialError) {
    return (
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <h2 className={styles.title}>Acceso Restringido</h2>
          <div className={styles.errorContainer}>
            <p className={styles.errorText}>
              Error de credenciales. Por favor, verifica tu usuario y contraseña.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.contentContainer}>
        <h2 className={styles.title}>Videos</h2>
        <ChangeVideo videos={videos} handleSubmit={handleSubmit} />
        <h2 className={styles.title}>Emails</h2>
        <div>
          <div className={styles.emailContainer}>
            <h3 className={styles.titleEmail}>
              Eliminar Email de la base de datos
            </h3>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`${styles.newUrl} ${styles.emailInput}`}
              type="text"
              placeholder="Email"
            />
            <button
              disabled={loadingEmail}
              onClick={handleDeleteEmail}
              className={`${styles.button} ${styles.deleteEmail}`}
            >
              {loadingEmail ? "Cargando..." : "Eliminar"}
            </button>
          </div>
          <div className={styles.errorMessage}>
            {errorEmailMessage && <p>{errorEmailMessage}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}