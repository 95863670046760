import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  usuario: {},
  isLogged: false,
  isAdmin: false,
  isFetching: false,
  error: false,
};

const persistConfig = {
  key: "root",
  storage,
};

export const loginUser = createAsyncThunk(
  `users/loginUser`,
  async ({ dni, password }, { rejectWithValue }) => {
    try {
      const response = await fetch(
        "https://api-tabaquera-psi.vercel.app/api/users/auth",
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ dni, password }),
        }
      );
      if (response.status === 404) {
        throw new Error("usuario no encontrado");
      }
      return await response.json();
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  `users/registerUser`,
  async ({ nombre, email, password, fecha_nacimiento, dni }) => {
    try {
      const response = await fetch(
        "https://api-tabaquera-psi.vercel.app/api/users",
        {
          method: "POST",
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ nombre, email, password, fecha_nacimiento, dni }),
        }
      );
      return await response.json();
    } catch (error) {
      return 'ocurrio un error';
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLogged = false;
      state.isAdmin = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.usuario = action.payload.usuario;
      state.isLogged = action.payload.isLogged;
      state.isAdmin = action.payload.isAdmin;
      state.isFetching = false;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.usuario = {};
      state.isLogged = false;
      state.isAdmin = false;
      state.isFetching = false;
      state.error = true;
    });
    builder.addCase(registerUser.pending, (state, action) => {
      state.isLogged = false;
      state.isAdmin = false;
      state.isFetching = true;
      state.error = false;
    });
    builder.addCase(registerUser.fulfilled, (state, action) => {
      state.usuario = action.payload.usuario;
      state.isLogged = true;
      state.isAdmin = false;
      state.isFetching = false;
    });
    builder.addCase(registerUser.rejected, (state, action) => {
      state.usuario = {};
      state.isLogged = false;
      state.isAdmin = false;
      state.isFetching = false;
      state.error = true;
    });
  },
});

const persistedReducer = persistReducer(persistConfig, userSlice.reducer);

export default persistedReducer;
