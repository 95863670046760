export const validateName = (value) => {
  const regex = /^[A-Za-z]+([ A-Za-z]+)*/;
  return regex.test(value) || "Nombre Inválido";
};

export const validateLastname = (value) => {
  const regex = /^[A-Za-z]+([ A-Za-z]+)*/;
  return regex.test(value) || "Apellido Inválido";
};

export const validateEmail = (value) => {
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return regex.test(value) || "Email inválido";
};

export const validateCity = (value) => {
  const regex = /^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/;
  return regex.test(value) || "Nombre de ciudad invalida";
};
export const validateProvince = (value) => {
  const regex = /^[A-Za-z]+(?:[\s-][A-Za-z]+)*$/;
  return regex.test(value) || "Nombre de provincia invalida";
};

export const validateEmailMatch = (value, allValues) => {
  return value === allValues.email || "Los Emails no coinciden";
};

export const validatePhoneMatch = (value, allValues) => {
  return value === allValues.resendPhone || "Phone numbers do not match";
};