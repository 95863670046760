import React from 'react'
import styles from './marcas.module.css'
import { useNavigate } from "react-router-dom";

import logo1 from "../../assets/PIER-COLOR.svg";
import logo2 from "../../assets/PIER-ROLL-COLOR.svg";
import logo3 from "../../assets/Corona/corona-dorado.svg";
import logo4 from "../../assets/DOLCHESTER-COLOR.svg";
import logo5 from "../../assets/LIVERPOOL-GRIS.svg";


export default function Marcas() {
  const navigate = useNavigate();

  return (
    <section className={styles.section3}>
      <div className={styles.section3Box}>
        {/* <h3>conocé nuestras marcas</h3> */}
        <div className={styles.flex}>
          <img src={logo3} loading='lazy' alt="tres logos marcas" className={`${styles.tresLogos4} ${styles.coronaLogo}`} onClick={() => navigate('/corona')} />
          <img src={logo1} loading='lazy' alt="tres logos marcas" className={styles.tresLogos2} onClick={() => navigate('/Pier')} />
          <img src={logo5} loading='lazy' alt="tres logos marcas" className={styles.tresLogos5} onClick={() => navigate('/Liverpool')} />
          <img src={logo4} loading='lazy' alt="tres logos marcas" className={styles.tresLogos3} onClick={() => navigate('/Dolchester')} />
          <img src={logo2} loading='lazy' alt="tres logos marcas" className={styles.tresLogos1} onClick={() => navigate('/Pier&Roll')} />
        </div>
      </div>
    </section>
  )
}
