import React, { useState, useEffect } from "react";
import styles from "./modalEmail.module.css";
import emailjs from "emailjs-com";
import { emailDeleteCredentials, passwordCredentials } from "../../utils/emailCredentias.js";

export default function ModalEmail({ showModal, onClose, text, reset }) {
  const [email, setEmail] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (event) => {
    setFormSubmitted(true);
    event.preventDefault();

    if (reset) {
      const password = Math.random().toString(36).substring(2, 8);
      const response = await fetch("https://api-tabaquera-psi.vercel.app/api/reset-pass", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          newPassword: password,
        }),
      });
      if(response.status === 200){
        // ver credenciales de este endpoint
        const { serviceId, templateId, acountId } = passwordCredentials
        await emailjs.send(
          serviceId,
          templateId,
          { password, email },
          acountId
        );
      }
    } else {
      const { serviceId, templateId, acountId } = emailDeleteCredentials;
      await emailjs.send(serviceId, templateId, { email }, acountId);
    }
  };

  useEffect(() => {
    if (!showModal) {
      setEmail("");
    }
  }, [showModal]);

  return (
    <div>
      {showModal ? (
        <div className={styles.modal}>
          <form onSubmit={handleSubmit} className={styles.loginFormModal}>
            <div>
              <label htmlFor="email">{text}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
        
              {formSubmitted ? 
              "" : 
              <button type="submit" className={styles.footerButton} disabled={formSubmitted}>Enviar</button> 
              }
            <button className={styles.footerButton} onClick={onClose}>
              {formSubmitted ? "Cerrar" : "Cancelar"}
            </button>
          </form>
        </div>
      ) : null}
    </div>
  );
}
