/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import styles from "./inicio.module.css";
import Marcas from "../../components/marcas/Marcas";
import Footer from "../../components/footer/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import firstImage from "../../assets/Carousel/1.png";
import secondImage from "../../assets/Carousel/2.png";
import thirdImage from "../../assets/Carousel/3.png";
import fourthImage from "../../assets/Carousel/4.png";
import fifthImage from "../../assets/Carousel/5.png";

export default function Inicio() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: "false",
    arrows: false,

  };

  const images = [firstImage, secondImage, thirdImage, fourthImage, fifthImage];

  return (
    <>
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index + 1}`} className={styles.carouselImage} />
            </div>
          ))}
        </Slider>
      <Marcas />
      <Footer />
    </>
  );
}
