import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";
import { useForm } from "react-hook-form";
import {
  validateEmail,
  validateCity,
  validateName,
  validateProvince,
  validateLastname,
} from "../../utils/validations";
import Footer from "../../components/footer/Footer.jsx";
import { useLocation } from "react-router-dom";
import emailjs from "emailjs-com";
import { credentialsContact } from "../../utils/emailCredentias.js";

export default function Contacto() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async ({ resendEmail, resendPhone, ...rest }) => {
    if (isLoading) return
    setIsLoading(true);
    const { serviceId, templateId, acountId } = credentialsContact;
    const template = {
      city: rest.city,
      email_sub: rest.email,
      lastname: rest.lastname,
      message: rest.message,
      name: rest.name,
      phone: rest.phone,
      province: rest.province
    };
    await emailjs
      .send(serviceId, templateId, template, acountId)
      .then((response) => {
        alert("Gracias por escribirnos. Recibimos tu mensaje correctamente.");
        navigate("/inicio")
      })
      .catch((error) => {
        setErrorMessage("Ocurrio un error al enviar el mensaje");
        return setTimeout(() => {
          setErrorMessage("");
        }, 3000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);


  return (
    <>
      <section>
        <div className={styles.thirdSection}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1766.0314020949818!2d-64.2055361!3d-27.7153471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x943b51003c090e31%3A0x25a9e27add682a74!2sReal%20tabacalera!5e0!3m2!1ses!2sar!4v1726429195650!5m2!1ses!2sar&zoom=9"
            width="600"
            height="500"
            style={{ width: "100%", height: "500px" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="Mapa"
          />
        </div>

        <div id="contacto" className={styles.fourthSection}>
          <div className={styles.firstBox}>
            <h2 className={styles.titleForm}>CONTACTO</h2>
            <p className={styles.subtitle}>
              Para dejarnos tus comentarios o postularte como distribuidor de
              nuestras marcas por favor completa el siguiente formulario.
            </p>
            <p className={styles.rrhhText}>
              Para dejar tu curriculum escribinos a{" "}
              <a href="recursoshumanos@realtabacalera.com">
                recursoshumanos@realtabacalera.com
              </a>
            </p>
          </div>

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={styles.secondBoxForms}
          >
            <div className={styles.firstPartForm}>
              <div className={styles.boxImputs}>
                <div className={styles.containerInput}>
                  <input
                    className={styles.inputs}
                    placeholder="Nombre"
                    {...register("name", {
                      required: true,
                      validate: validateName,
                    })}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <span className={styles.error}>
                      Este campo es requerido
                    </span>
                  )}
                  {errors.name && errors.name.type !== "required" && (
                    <span className={styles.error}>{errors.name.message}</span>
                  )}
                </div>
                <div className={styles.containerInput}>
                  <input
                    className={styles.inputs}
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      validate: validateEmail,
                    })}
                  />
                  {errors.email && errors.email.type === "required" && (
                    <span className={styles.error}>
                      Este campo es requerido
                    </span>
                  )}
                  {errors.email && errors.email.type !== "required" && (
                    <span className={styles.error}>{errors.email.message}</span>
                  )}
                </div>
                <div className={styles.containerInput}>
                  <input
                    className={styles.inputs}
                    placeholder="Provincia"
                    {...register("province", {
                      required: true,
                      validate: validateProvince,
                    })}
                  />
                  {errors.city && errors.city.type === "required" && (
                    <span className={styles.error}>
                      Este campo es requerido
                    </span>
                  )}
                  {errors.city && errors.city.type !== "required" && (
                    <span className={styles.error}>{errors.city.message}</span>
                  )}
                </div>
              </div>
              <div className={styles.boxImputs}>
                <div className={styles.containerInput}>
                  <input
                    className={styles.inputs}
                    placeholder="Apellido"
                    {...register("lastname", {
                      required: true,
                      validate: validateLastname,
                    })}
                  />
                  {errors.lastname && errors.lastname.type === "required" && (
                    <span className={styles.error}>
                      Este campo es requerido
                    </span>
                  )}
                  {errors.lastname && errors.lastname.type !== "required" && (
                    <span className={styles.error}>
                      {errors.lastname.message}
                    </span>
                  )}
                </div>
                <div className={styles.containerInput}>
                  <input
                    className={styles.inputs}
                    placeholder="Teléfono"
                    {...register("phone", {
                      required: true,
                    })}
                  />
                  {errors.phone && errors.phone.type === "required" && (
                    <span className={styles.error}>
                      Este campo es requerido
                    </span>
                  )}
                </div>
                <div className={styles.containerInput}>
                  <input
                    className={styles.inputs}
                    placeholder="Ciudad"
                    {...register("city", {
                      required: true,
                      validate: validateCity,
                    })}
                  />
                  {errors.city && errors.city.type === "required" && (
                    <span className={styles.error}>
                      Este campo es requerido
                    </span>
                  )}
                  {errors.city && errors.city.type !== "required" && (
                    <span className={styles.error}>{errors.city.message}</span>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.lastPartForm}>
              <input
                className={styles.messageImput}
                placeholder="Mensaje"
                {...register("message", { required: true })}
              />
              {errors.message && (
                <span className={styles.error}>Este campo es requerido</span>
              )}
              <div className={styles.sendForm}>
                <p className={styles.textForm}>
                  Todos los campos son obligatorios
                </p>
                <button type="submit" className={styles.buttonForm}>
                  {isLoading ? "Enviando..." : "Enviar"}
                </button>
              </div>
              <p className={styles.error}>{errorMessage}</p>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}
