import React, { useEffect } from "react";
import styles from "./styles.module.css";
import logo1 from "../../assets/Corona/logo_real_tabacalera.png";
import band1 from "../../assets/Corona/band_sant.png";
import band2 from "../../assets/Corona/band_arg.png";
import Footer from "../../components/footer/Footer.jsx";
import { useLocation } from "react-router-dom";

export default function Empresa() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);


  return (
    <>
      <section className={styles.container}>
        <div className={styles.firstSection}>
          <img src={logo1} alt="Real Tabacalera de Santiago" className={styles.mainLogo} />
          <div className={styles.band}>
            <img src={band1} alt="Bandera" />
            <img src={band2} alt="Bardera" />
          </div>
        </div>
        <div className={styles.subtitlesContainer}>
          <h2 className={styles.title}>REAL TABACALERA de SANTIAGO</h2>
          <h3 className={styles.title}>Impulsando el Desarrollo Tabacalero en Santiago del Estero</h3>
        </div>
        <div className={styles.secondSection}>
          <div className={styles.firstBox}>
            <p className={styles.subtitle}>
              REAL TABACALERA de Santiago es una empresa emergente con sede en La
              Banda, en la provincia de Santiago del Estero, Argentina. Con una visión clara
              y un compromiso firme, esta compañía se ha propuesto transformar y revitalizar
              la industria tabacalera en la región. Santiago del Estero, conocida por
              sus vastas tierras y condiciones climáticas ideales para el cultivo de tabaco,
              encuentra en REAL TABACALERA de Santiago un aliado estratégico para potenciar
              su economía local.
            </p>
            <p className={`${styles.subtitle} ${styles.spaceSubtitle}`}>
              La misión de REAL TABACALERA de Santiago es simple pero ambiciosa:
              diseñar, desarrollar y producir productos de alta calidad relacionados con el
              tabaco. Aunque la empresa no se dedica al cultivo del tabaco, selecciona
              cuidadosamente las mejores hojas de tabaco de proveedores de confianza
              para crear productos innovadores y atractivos para el mercado. Cada etapa
              del proceso de producción es supervisada con rigor para garantizar que los
              productos cumplan con los más altos estándares de calidad.
            </p>
            <p className={`${styles.subtitle} ${styles.spaceSubtitle}`}>
              Además de su enfoque en la calidad, REAL TABACALERA de Santiago se compromete
              a impulsar el desarrollo económico de la provincia. La empresa no
              solo genera empleo directo a través de sus operaciones, sino que también fomenta
              la creación de empleo indirecto al trabajar en colaboración con agricultores
              locales, proveedores y otros actores de la cadena productiva. Esta sinergia
              contribuye a la prosperidad de la comunidad, mejorando las condiciones
              de vida de muchas familias en la región.
            </p>
          </div>
          <div className={styles.firstBox}>
            <p className={styles.subtitle}>
              REAL TABACALERA de Santiago no solo se centra en la producción, sino que
              también invierte en investigación y desarrollo para innovar en el mercado tabacalero.
              Con un equipo de expertos en agronomía, tecnología y marketing, la
              empresa está preparada para enfrentar los desafíos de un mercado en constante
              evolución y satisfacer las demandas de los consumidores más exigentes.
            </p>
            <p className={`${styles.subtitle} ${styles.spaceSubtitle}`}>
              En resumen, REAL TABACALERA de Santiago se perfila como un pilar fundamental
              para el desarrollo de la actividad tabacalera en Santiago del Estero,
              combinando tradición y modernidad para llevar productos de alta calidad a
              los mercados locales e internacionales. Con su dedicación al crecimiento
              económico y su compromiso con la calidad, esta empresa promete un futuro
              próspero y sostenible para la industria tabacalera en la región.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}