import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./registro.module.css";
import body from "../../assets/background-login.jpg";
import { useDispatch } from "react-redux";
import { registerUser } from "../../redux/usersSlice.js";

export default function Registro() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dni, setDni] = useState("");
  const [password, setPassword] = useState("");
  const [fecha_nacimiento, setFechaNacimiento] = useState("a");
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [acceptedConditions, setAcceptedConditions] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event, setter) => {
    setter(
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value
    );
  };

  const handleSubmit = async (event) => {
    setIsLoading(true)
    event.preventDefault();
    const { payload } = await dispatch(
      registerUser({ nombre, email, password, fecha_nacimiento, dni })
    );
    setIsLoading(false)
    if (!!payload.user.dni) return navigate("/inicio");
    setErrorMessage('Ocurrió un error')
    return setTimeout(() => setErrorMessage('', 10000))
  };

  function incorrectAge(DOB) {
    const today = new Date();
    const birthDate = new Date(DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age < 18 && fecha_nacimiento !== "a";
  }

  const checkFields = () => {
    return (
      !acceptedConditions ||
      !!incorrectAge(fecha_nacimiento) ||
      !fecha_nacimiento ||
      !dni ||
      !password ||
      !email ||
      !nombre
    );
  };

  return (
    <div className={styles.login}>
      <img
        src={body}
        alt="imagen de fondo login"
        className={styles.background}
      />

      <h1>¡Bienvenido!</h1>
      <form onSubmit={handleSubmit} className={styles.loginForm}>
        <h3>Ingresá tus datos</h3>
        <div className={styles.loginFormBody}>
          <div className={styles.loginFormInput}>
            <label htmlFor="nombre">Nombre completo</label>
            <input
              type="text"
              id="nombre"
              value={nombre}
              onChange={(e) => handleChange(e, setNombre)}
              required
            />
          </div>

          <div className={styles.loginFormInput}>
            <label htmlFor="dni">dni</label>
            <input
              type="number"
              id="dni"
              value={dni}
              onChange={(e) => handleChange(e, setDni)}
              required
            />
          </div>

          <div className={styles.loginFormInput}>
            <label htmlFor="password">Fecha de Nacimiento</label>
            <input
              type="date"
              id="fecha"
              value={fecha_nacimiento}
              onChange={(e) => handleChange(e, setFechaNacimiento)}
              required
            />
          </div>

          <div className={styles.loginFormInput}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => handleChange(e, setEmail)}
              required
            />
          </div>

          <div className={styles.loginFormInput}>
            <label htmlFor="password">Contraseña</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => handleChange(e, setPassword)}
              required
            />
          </div>
          <div className={styles.conditions}>
            <input
              type="checkbox"
              id="conditions"
              checked={acceptedConditions}
              onChange={(e) => handleChange(e, setAcceptedConditions)}
            />
            <label htmlFor="conditions">
              Estoy de acuerdo con los términos y condiciones descriptos abajo y
              con la política de privacidad de Real Tabacalera.
            </label>
          </div>
        </div>

        <p className={styles.errorMessage}>{errorMessage}</p>

        <button
          disabled={checkFields()}
          type="submit"
          className={styles.button}
        >
          {isLoading ? 'Cargando...' : 'Abrir mi cuenta'}
        </button>
        <button onClick={() => navigate("/")} className={styles.button}>
          Iniciar sesion
        </button>
      </form>

      <div className={styles.terminos}>
        <p>
          Sólo podrán acceder aquellos fumadores mayores de 18 años que residan
          en la República Argentina. Al proporcionar tus datos podemos
          validarlos y asegurar su cumplimiento. Massalin Particulares S.R.L.
          por ley y política de responsabilidad asume el compromiso de
          comunicarse exclusivamente con fumadores adultos mayores de 18 años,
          residentes en la República Argentina pertenecientes a su base de
          datos. (Los datos serán verificados por un tercero y/o constatados a
          través de páginas web de información de público acceso) Massalin
          particulares S.R.L. confeccionará una base de datos de fumadores
          adultos con la finalidad de poder remitirles comunicaciones,
          información, publicidad y materiales promocionales referentes a
          productos de tabaco y asuntos relacionados con el tabaco (Art. 6 Ley
          25.326). El titular de los datos personales tiene la facultad de
          ejercer el derecho de acceso a los mismos en forma gratuita a
          intervalos no inferiores a seis meses, salvo que se acredite un
          interés legítimo al efecto conforme lo establecido en el artículo 14,
          inciso 3 de la Ley Nº 25.326. La DIRECCION NACIONAL DE PROTECCION DE
          DATOS PERSONALES, Órgano de Control de la Ley Nº 25.326, tiene la
          atribución de atender las denuncias yreclamos que se interpongan con
          relación al incumplimiento de las normas sobre protección de datos
          personales (Disposición 10/08).
        </p>
      </div>

      <div className={styles.linksLegales}>
        <p>legales</p>
        <p>condiciones</p>
        <p>salir de la base de datos</p>
      </div>
    </div>
  );
}
