export const credentialsContact = {
  serviceId: 'service_340wgou',
  templateId: 'template_duuw6dd',
  acountId: '_gNIyxmx45R4hHR0k'
}
export const emailDeleteCredentials = {
  serviceId: 'service_5tm8v4d',
  templateId: 'template_vmai0cs',
  acountId: 'bTcrIJsKs5xV1WCOP'
}

export const passwordCredentials = {
  serviceId: 'service_flc7c9c',
  templateId: 'template_uklyrzk',
  acountId: '6mQZBI1l4Q2Fuv_5S'
}
export const newsletterCredentials = {
  serviceId: 'service_340wgou',
  templateId: 'template_f6l63gr',
  acountId: '_gNIyxmx45R4hHR0k'
}
